@media (min-width: 425px) and (max-width: 1024px){
    .dashboard .panel .header .btn{ margin-bottom: 10px; }
    .dashboard .multiGraph{ flex-direction: column; 
        & .panel{ width: unset; margin-right: 0 !important; }
    }
    .debitos-import{
        & .panel .multiInput{ flex-direction: column; 
            & div{ margin-bottom: 14px; }
            & div, & div label{ display: block !important; }
            & select{ width: 100%; }
        }
    }
    .pagamentos{
        & .filtros .panel{ flex-direction: column; align-items: center;
            & input{ margin-right: 0 !important; }
            & p{ margin: 20px 0 0; }
        }
    }
    .pagamentos .panel.pagto{ overflow-x: scroll; padding-left: 0; }
}

@media (min-width: 940px) and (max-width: 1212px){
    // .multiInput.cinco div{
    //     & label{ font-size: 10px; }
    //     & input, & select{ font-size: 13px; }
    //     & select{ height: 38px !important; }
    // }
}

@media (max-width: 570px){
    .sideMenuBar{ display: none; }
    .body{ margin-left: 0; }
    .dashboard {
        & .panel .header{ flex-direction: column; text-align: center;
            & .btn{ margin-bottom: 10px; }
        }
        & .faturamento{ margin-left: unset !important; }
        & .multiGraph{ flex-direction: column; 
            & .panel{ width: unset; margin-right: 0 !important; }
        }
    }
    .clientes{ 
        & .filtros .panel{ flex-direction: column;
            & select{ width: unset; margin-right: 0; }
            & button{ width: 100%; }
        }
        & .detalhes .info p{ width: 100%; text-align: center; }
    }
    .debitos, .pagamentos{
        & .tituloButton{ flex-direction: column; }
    }
    .debitos-import{
        & .panel .multiInput{ flex-direction: column; 
            & div{ display: block !important; }
            & button{ min-width: 200px !important; margin-bottom: 20px; margin-right: 0 !important; }
            & select{ margin-right: 0 !important; width: 100%; }
        }
        & .acoes{ flex-direction: column; 
            & a{ margin-bottom: 10px; }
            & a, button{ width: 100%; }
        }
    }
    .pagamentos{
        & .filtros .panel{ flex-direction: column; 
            & input{ width: unset; margin-right: 0 !important; }
            & p{ text-align: center; margin: 20px 0 0; }
            & button{ width: 100%; }
        }
        & .infoPag{ flex-direction: column; margin-left: 0;
            & p{ width: 100%; text-align: center; }
        }
    }

    .clientes .detalhes .panel, .debitos .panel{ overflow-x: scroll; padding-left: 0; }
    .footer{ padding: 2.5px 0; }
}

@media (min-width: 730px) {
    .linksBar { display: flex !important; }
}

@media (min-width: 768px) {
    .container { width: 750px; }
}

@media (min-width: 992px) {
    .container { width: 970px; }
}

@media (min-width: 1200px) {
    .container { width: 1200px; }
    // .multiInput.cinco div{ width: auto; margin-bottom: 20px; }
}

@media (max-height: 720px){
    .login .contentLogin{
        & .logo{ margin-top: -63px; }
        & .acessaForm {
            & h2{ margin-top: -20px; margin-bottom: 30px; }
            & .btn.entrar{ margin-top: 15px; }
        }
    }
}

