.login { width: 100vw; height: 100vh; display: flex; justify-content: center;
    & .contentLogin{ display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;
        & .acessaForm{ margin-top: 60px;
            & h2{ margin-bottom: 60px; }
            & div{ display: flex; flex-direction: column; margin-bottom: 20px;
                & label{ color: #888888; font-size: 16px; font-weight: bold; text-align: left; }
                & input{ border: none; border-bottom: 1px solid #EEEEEE; font-size: 16px; padding-bottom: 9px; margin-top: 18px; 
                    &::placeholder{ color: #A4A4A4; }
                }
            }
            & div:nth-child(3){ margin-bottom: 16px; }
            & a.esqueciSenha{ text-decoration: none; color: #777; font-size: 14px; float: right; }
            & .btn.entrar{ padding: 13.5px 0; margin-top: 20px; margin-bottom: 26px; background-color: var(--baseColor); color: #fff; width: 300px; font-size: 20px; font-weight: bold; 
                &:hover{ background-color: #4a186b; }
            }
            & p{ color: #333; text-align: center; 
                & a { text-decoration: none; font-weight: 500; }
            }
        }
        & .divulgacao{  display: flex;  position: absolute; bottom: 24px;
            & div > p{ font-size: 12px; color: #999; font-weight: 500; text-align: left; }
            & div > img{ margin-right: 28px; }
            & .licenciador{ margin-left: 50px; }
        }
    }
}

.dashboard{ margin: 44px 40px 70px; 
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panel{
        & .header{ display: flex; align-items: center;
            & .filtros{ margin-bottom: 32px;
                & button{ border: 1px solid #06014A; color: #06014A; font-size: 15px; width: 120px; border-radius: 20px; margin-right: 20px; background-color: transparent; }
                & button.active{ background-color: #06014A; color: #FFFFFF; }
            }
            & .faturamento{ margin-left: auto; text-align: right;
                p{ margin-top: 0; margin-bottom: 0; }
                h3{ margin-top: 0; font-size: 24px; font-weight: bold; }
            }
        }
    }
    & .multiGraph{ display: flex; 
        & .panel{ display: flex; flex-direction: column; width: 50vw;
            & p.titulo{ font-size: 16px; font-weight: bold; }
            & canvas{ margin-top: auto; }
        }
        & .panel.mr{ margin-right: 60px; }
    }
}

.clientes{ margin: 44px 40px 70px; 
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .filtros{
        & .panel{ display: flex;
            & input { margin-right: 40px; height: 44px; }
            & select{ margin-right: 40px; width: 200px; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; background-color: var(--baseColor); }
        }
    }
    & .detalhes{
        & .panel{ margin-bottom: 22px; }
        & .info{ display: flex; align-items: center; margin-left: 29px;
            & p{ font-size: 16px; color: #4F4F4F; }
            & .paginacao{ margin-left: auto; }
        }
    }
}

.debitos{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid var(--baseColor); color: var(--baseColor); width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .table{
        & tbody tr:last-child{
            & td{ padding-top: 14px; border-bottom: none;
                &:nth-child(1){ font-size: 15px; color: #4F4F4F; text-align: left !important; text-indent: 8px; }
                &:nth-child(3),&:nth-child(4){ color: var(--baseColor); font-size: 18px; font-weight: bold; }
            }
        }
    }
}

.debitos-import{ margin: 44px 40px 70px; 
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panelBody{ padding-left: 10px; 
        & .multiInput{ display: flex; align-items: center; padding-bottom: 23px;
            & .btnInput{ margin-right: 35px; }
            & select{ height: 50px; }
        }
        & .baixaModelo{ text-decoration: underline; color: #4F4F4F; }
    }
    & .acoes{ display: flex; align-items: center;
        & a{ font-weight: 500; font-size: 16px; width: 110px; text-align: center; color: #999999; border: 1px solid #BDBDBD; 
            &:hover{ background-color: #999999; color: #F8F9FB; }
        }
        & button{ margin-left: auto; font-weight: 500; width: 175px; padding: 11px 0; background-color: var(--baseColor); color: #FFFFFF; 
            &:hover{ background-color: #4A186B; }
        }
    }
}

.pagamentos{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid var(--baseColor); width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .filtros{
        & .panel{ display: flex;
            & input{ width: 180px; padding: 10.5px; }
            & p{ margin: auto 15px 0; font-size: 22px; font-weight: 300; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; background-color: var(--baseColor); }
        }
    }
    & .info{ display: flex; align-items: center; margin-left: 20px;
        & p.totais{ font-size: 15px; color: #4F4F4F; font-weight: 500; }
        & p.valores{ font-size: 18px; font-weight: bold; margin-left: auto; text-align: right; margin-right: 18px; color: var(--baseColor); }
    }
    & .infoPag{ display: flex; align-items: center; margin-left: 29px;
        & p{ font-size: 16px; color: #4F4F4F; }
        & .paginacao{ margin-left: auto; }
    }
}

.liquida-boleto{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid var(--baseColor); width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .filtros{
        & .panel{ display: flex;
            & input{ width: 180px; padding: 10.5px; }
            & p{ margin: 0; font-size: 22px; font-weight: 300; }
            & select{ margin-right: 20px; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; background-color: var(--baseColor); }
        }
    }
    & .info{ display: flex; align-items: center; margin-left: 20px;
        & p.totais{ font-size: 15px; color: #4F4F4F; font-weight: 500; }
        & p.valores{ font-size: 18px; font-weight: bold; margin-left: auto; text-align: right; margin-right: 18px; color: var(--baseColor); }
    }
    & .infoPag{ display: flex; align-items: center; margin-left: 29px;
        & p{ font-size: 16px; color: #4F4F4F; }
        & .paginacao{ margin-left: auto; }
    }
    & .inLine{ display: flex; flex-direction: row; align-items: center; 
        & .label{ font-size: 16px; color: #4F4F4F; margin-right: 8px; }
        & .value{ font-size: 18px; font-weight: bold; color: #06014A; }
        & .title{ font-weight: 500; font-size: 15px; color: #4F4F4F; }
        & .valor{ font-size: 18px; color: #4F4F4F; }
    }
}

.negociacoes{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid var(--baseColor); width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .panel {
        &.pagto{
            & .table tbody tr:last-child {
                & td{ border-bottom: none; padding-top: 24px; 
                    &:nth-child(1){ font-weight: 500; font-size: 16px; color: #4F4F4F; }
                    &:nth-child(5){ font-weight: bold; font-size: 18px; color: #06014A; }
                }
            }
        }
        & .panelBody{ padding-left: 10px; 
            & .multiInput{ display: flex; flex-wrap: wrap; padding-bottom: 23px;
                & div:first-child, & div:nth-child(2), & div:nth-child(3), & div:nth-child(4), & div:nth-child(5){ margin-bottom: 20px; }
                & input{ padding: 10.5px; margin-right: 30px; }
                & .btnInput{ margin-right: 35px; }
                & select{ height: 50px; margin-right: 30px; }
            }
        }
    }
    & .filtros{
        & .panel{ display: flex;
            & input{ width: 180px; padding: 10.5px; }
            & p{ margin: auto 15px 0; font-size: 22px; font-weight: 300; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; background-color: var(--baseColor); }
        }
    }
    & .info{ display: flex; align-items: center; margin-left: 29px;
        & p{ font-size: 16px; color: #4F4F4F; }
        & .paginacao{ margin-left: auto; }
    }
    & .acoes.action{ display: flex; align-items: center;
        & a{ font-weight: 500; font-size: 16px; width: 110px; text-align: center; color: #999999; border: 1px solid #BDBDBD; 
            &:hover{ background-color: #999999; color: #F8F9FB; }
        }
        & button{ margin-left: auto; font-weight: 500; width: 175px; padding: 11px 0; background-color: var(--baseColor); color: #FFFFFF; 
            &:hover{ background-color: #4A186B; }
        }
    }
}

.modalLink{
    p{ font-weight: bold; font-size: 16px; text-transform: uppercase; color: #999; margin-bottom: 14px; }
    h4{ font-weight: normal; font-size: 16px; line-height: 24px; text-align: center; }
    button.btn{ width: 100%; height: 40px; background-color: #4F4F4F; font-weight: 500; font-size: 16px; text-align: center; color: #FFF; margin-bottom: 14px; text-transform: uppercase; border-radius: 4px; }
    button.btnCancelar{ margin-top: 2px; font-size: 16px; text-decoration: underline; color: #EB5757; }
    button.btnFechar{ width: 100%; height: 40px; background-color: #4F4F4F; font-weight: 500; font-size: 16px; text-align: center; color: #FFF; border-radius: 4px; }
}

.messaging{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid var(--baseColor); color: var(--baseColor); width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .panel{
        & .panelBody{ padding-left: 10px; 
            & .expandable{ margin-bottom: 22px;
                & .btnExpandable{ font-weight: bold; font-size: 14px; letter-spacing: -0.333333px; margin-left: 0; padding-left: 0; }
            }
            & .multiInput{ display: flex; align-items: center; flex-wrap: wrap; padding-bottom: 23px;
                &.selectsPadding select{ margin-right: 30px; }
                & div{ margin-bottom: 20px; }
                & input{ padding: 10.5px; margin-right: 30px; }
                & .btnInput{ margin-right: 35px; }
                & select{ height: 50px; }
            }
        }
    }
    & .actions{ display: flex; align-items: center;
        & a{ font-weight: 500; font-size: 16px; width: 110px; text-align: center; color: #999999; border: 1px solid #BDBDBD; 
            &:hover{ background-color: #999999; color: #F8F9FB; }
        }
        & button{ margin-left: auto; font-weight: 500; width: 175px; padding: 11px 0; background-color: var(--baseColor); color: #FFFFFF; 
            &:hover{ background-color: #4A186B; }
        }
    }
}

.configuracao{ margin: 44px 40px 70px;
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid var(--baseColor); width: 175px; font-weight: 500; font-size: 16px; }
    }
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panel.senhas{
        & input{ width: 280px; padding: 9.5px; }
    }
    & .panel{
        & .panelBody{ padding-left: 10px; 
            &.menus{
                & .menu{ border-bottom: 1px solid #DFDFDF; padding: 10px 0 10px 10px; 
                    &.filho{ border-top: 1px solid #DFDFDF; border-bottom: 0; margin-top: 10px; padding-bottom: 0; }
                }
                & .border{ border-bottom: 1px solid #DFDFDF; }
                & label{ display: flex; align-items: center; font-size: 14px; color: #4F4F4F }
                & input{ margin-right: 9px; margin-left: 0; }
            }
            & .multiInput{ display: flex; align-items: center; flex-wrap: wrap; padding-bottom: 23px;
                & div{ margin-bottom: 20px; }
                & input{ padding: 10.5px; margin-right: 30px; }
                & .btnInput{ margin-right: 35px; }
                & select{ height: 50px; }
            }
        }
    }
    & .actions{ display: flex; align-items: center;
        & a{ font-weight: 500; font-size: 16px; width: 110px; text-align: center; color: #999999; border: 1px solid #BDBDBD; 
            &:hover{ background-color: #999999; color: #F8F9FB; }
        }
        & button{ margin-left: auto; font-weight: 500; width: 175px; padding: 11px 0; background-color: var(--baseColor); color: #FFFFFF; 
            &:hover{ background-color: #4A186B; }
        }
    }
}